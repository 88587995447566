import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "@layout/index";
import BottomLayout from "@/layout/BottomLayout";
import TopCardWrap from "@/content/use-cases/TopCardWrap";
import GraphicText from "@/layout/GraphicText";

import Header from "@components/head";

import {
  Text,
  List,
  Button,
  ListItem,
  Box,
  Link as ChakraLink,
  VStack,
  Flex,
} from "@chakra-ui/react";
import { HOST_URL } from "@/utils/constant";

const { useState } = React;

export const Head = () => {
  return (
    <Header
      ogUrl={`${HOST_URL}/use-cases/recruitment-interviews`}
      title="Better Recruitment Interviews Lead to Better Hires | Airgram for Hiring Workflow"
      description="Airgram speed up your hiring process with more in-depth interviews. Streamline remote recruiting workflow - schedule more efficiently, run interviews more smoothly and follow up with candidates more effortlessly."
    />
  );
};

const IndexPage = () => {
  return (
    <Layout
      bottomLayout={
        <BottomLayout
          signGtmId="web_sign_up_cta_btn_use_case"
          description="Better recruitment interviews lead to better hires."
          buttonText="Get Started Free"
          buttonLink="https://app.airgram.io/signup"
        />
      }
    >
      <TopCardWrap>
        <Text w="80%" textAlign="center" fontSize="32px" as="h1">
          Speed up your hiring process with more in-depth interviews
        </Text>
        <Text textAlign="center" fontSize={18}>
          Streamline remote recruiting workflow - schedule more efficiently, run
          interviews more smoothly and follow up with candidates more
          effortlessly.
        </Text>

        <ChakraLink
          _hover={{
            textDecor: "none",
          }}
          lineHeight="52px"
          target="_blank"
          mt="24px"
          href="https://app.airgram.io/signup"
        >
          <Button
            data-gtm="web_sign_up_cta_btn_use_case"
            size="lg"
            colorScheme="brand"
          >
            Get Started Free
          </Button>
        </ChakraLink>
      </TopCardWrap>

      <VStack my="80px" gap={{ base: "40px", md: "80px", lg: "120px" }}>
        {/* 左边图片，右边文字的布局 */}
        <GraphicText
          placement="right"
          title="Remove the friction of bulk scheduling"
          slot={
            <StaticImage
              loading="lazy"
              width={540}
              style={{ borderRadius: "12px" }}
              src="https://a.storyblok.com/f/167495/1460x834/051ac680f2/recruitmentinterviewsscheduling1.png"
              alt="Use Case Team"
            />
          }
        >
          <Flex
            flexDir="column"
            gap="12px"
            flex={1}
            bgColor="white"
            borderRadius="12px"
            color="#220247"
          >
            <Text fontSize={18}>
              Schedule remote interviews with potential hires efficiently. Pick
              the perfect time slot for both interviewers and interviewees. No
              more back and forth.
            </Text>
            <Text fontSize={18}>
              Share the interview agenda as well as important notice beforehand.
              Keep potential hires informed of job requirements thus to get
              fully prepared. Candidates are free to co-edit the agenda, sharing
              what's on their mind.
            </Text>
          </Flex>
        </GraphicText>

        {/* 左边文字，右边图片的布局 */}
        <GraphicText
          title="Conduct in-depth interviews, focus on potentials of candidates"
          slot={
            <StaticImage
              loading="lazy"
              width={540}
              style={{ borderRadius: "12px" }}
              src="https://a.storyblok.com/f/167495/828x512/1fb260a3f0/recruitmentinterviewstimestampspeaker1.png"
              alt="Google Meet Transcript"
            />
          }
        >
          <Flex
            flexDir="column"
            gap="12px"
            flex={1}
            bgColor="white"
            borderRadius="12px"
            color="#220247"
          >
            <Text fontSize={18}>
              Forget about jotting down everything manually while conducting an
              interview. Focus on the candidate's response, reasoning, and
              details that truly matter. Let Airgram take care of the recording
              and transcription of Zoom, Google Meet or Microsoft Teams
              interviews.
            </Text>

            <Text fontSize={18}>
              Besides, the following features can help you highlight key points
              more effectively:
            </Text>

            <List pl={0}>
              <ListItem>🔹 Timestamped notes</ListItem>
              <ListItem>🔹 Drag & drop transcript text to Notepad</ListItem>
              <ListItem>🔹 AI-powered entity extraction</ListItem>
              <ListItem>🔹 Auto-detect action items</ListItem>
            </List>
          </Flex>
        </GraphicText>

        <GraphicText
          placement="right"
          title="Brief candidates evaluation to stakeholders, make informed hiring decisions"
          slot={
            <StaticImage
              loading="lazy"
              width={540}
              style={{ borderRadius: "12px" }}
              src="https://a.storyblok.com/f/167495/824x578/fb2783d2af/recruitmentinterviewsshareintegrations.png"
              alt="Google Meet Collaborative Notes"
            />
          }
        >
          <Flex
            flexDir="column"
            gap="12px"
            flex={1}
            bgColor="white"
            borderRadius="12px"
            color="#220247"
          >
            <Text fontSize={18}>
              After an interview, play back the video recording at different
              speeds, select to listen to candidate’s remarks specifically,
              search and find key points.
            </Text>
            <Text fontSize={18}>
              Share insights to hiring stakeholders via Slack, Notion, or export
              them to Microsoft Word, Google Docs. Foster hiring transparency
              across the organization.
            </Text>
            <Text fontSize={18}>
              The valuable interview database with timestamped notes, HD video
              recordings, transcript - enables your team to make the most
              informative and concrete hiring decisions.
            </Text>
          </Flex>
        </GraphicText>
      </VStack>
    </Layout>
  );
};

export default IndexPage;
